#session-detail-page {
  .ion-padding span{
    padding: 2px 5px;
    border-radius: 10px;
    margin-top: 5px;
    color: white;
  }
  .session-track-ionic {
    color: var(--ion-color-primary);
  }
  .session-track-pre-congreso{
    background-color: var(--ion-color-pre-congreso);
  }
  .session-track-jic{
    background-color: var(--ion-color-jic)
  }
  .session-track-posters{
    background-color: var(--ion-color-posters)
  }
  .session-track-otros{
    background-color: var(--ion-color-otros)
  }
  .session-track-patrocinadores-vip{
    background-color: var(--ion-color-patrocinadores-vip)
  }
  .session-track-conferencia-plenaria{
    background-color: var(--ion-color-conferencia-plenaria)
  }
  .session-track-simposio{
    background-color: var(--ion-color-simposio)
  }
  .session-track-mesa-redonda{
    background-color: var(--ion-color-mesa-redonda)
  }
  .session-track-conferencia-por-invitación{
    background-color: var(--ion-color-conferencia-por-invitación)
  }
  .session-track-comunicaciones-orales{
    background-color: var(--ion-color-comunicaciones-orales)
  }
  .session-track-embajadas{
    background-color: var(--ion-color-embajadas)
  }
  .session-track-turbo-oral{
    background-color: var(--ion-color-turbo-oral)
  }
  .session-track-isit{
    background-color: var(--ion-color-isit)
  }
  .session-track-rpai{
    background-color: var(--ion-color-rpai)
  }
  .session-track-navigation{
    background-color: var(--ion-color-navigation)
  }

  .session-track-navigation {
    color: var(--ion-color-navigation);
  }

  .show-favorite {
    position: relative;
  }

  .icon-heart-empty {
    position: absolute;
    top: 5px;
    right: 5px;
    transform: scale(1);
    transition: transform 0.3s ease;
  }

  .icon-heart {
    position: absolute;
    top: 5px;
    right: 5px;
    transform: scale(0);
    transition: transform 0.3s ease;
  }

  .show-favorite .icon-heart {
    transform: scale(1);
  }

  .show-favorite .icon-heart-empty {
    transform: scale(0);
  }

  h1 {
    margin: 0;
  }
}
