/*
 * Material Design
 */

.md .session-list-filter ion-toolbar ion-button {
  text-transform: capitalize;
  letter-spacing: 0;
}

.md .session-list-filter ion-checkbox {
  --background-checked: transparent;
  --border-color: transparent;
  --border-color-checked: transparent;
  --checkmark-color: var(--ion-color-primary);
}

.md .session-list-filter ion-list {
  background: inherit;
}


/*
 * iOS
 */

.ios .session-list-filter ion-list-header {
  margin-top: 10px;
}

.ios .session-list-filter ion-label {
  color: var(--ion-color-primary);
}
