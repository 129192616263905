#schedule-page {
  ion-fab-button {
    --background: var(--ion-color-step-150, #fff);
    --background-hover: var(--ion-color-step-200, #f2f2f2);
    --background-focused: var(--ion-color-step-250, #d9d9d9);

    --color: var(--ion-color-primary, #3880ff);
  }

  /*
   * Material Design uses the ripple for activated
   * so only style the iOS activated background
   */
  .ios ion-fab-button {
    --background-activated: var(--ion-color-step-250, #d9d9d9);
  }

  .track-pill {
    padding: 2px 5px;
    margin-left: 5px;;
    border-radius: 5px;
    color: white;
  }

  .session-track-ionic {
    color: var(--ion-color-primary);
  }
  .session-track-pre-congreso{
    background-color: var(--ion-color-pre-congreso);
  }
  .session-track-jic{
    background-color: var(--ion-color-jic)
  }
  .session-track-posters{
    background-color: var(--ion-color-posters)
  }
  .session-track-otros{
    background-color: var(--ion-color-otros)
  }
  .session-track-patrocinadores-vip{
    background-color: var(--ion-color-patrocinadores-vip)
  }
  .session-track-conferencia-plenaria{
    background-color: var(--ion-color-conferencia-plenaria)
  }
  .session-track-simposio{
    background-color: var(--ion-color-simposio)
  }
  .session-track-mesa-redonda{
    background-color: var(--ion-color-mesa-redonda)
  }
  .session-track-conferencia-por-invitación{
    background-color: var(--ion-color-conferencia-por-invitación)
  }
  .session-track-comunicaciones-orales{
    background-color: var(--ion-color-comunicaciones-orales)
  }
  .session-track-embajadas{
    background-color: var(--ion-color-embajadas)
  }
  .session-track-turbo-oral{
    background-color: var(--ion-color-turbo-oral)
  }
  .session-track-isit{
    background-color: var(--ion-color-isit)
  }
  .session-track-rpai{
    background-color: var(--ion-color-rpai)
  }
  .session-track-navigation{
    background-color: var(--ion-color-navigation)
  }

  .session-track-navigation {
    color: var(--ion-color-navigation);
  }

  .session-track-navigation {
    color: var(--ion-color-navigation);
  }

  ion-item-sliding.track-pre-congreso ion-label{
    border-left: 4px solid var(--ion-color-precongreso);
    padding-left: 10px;
  }
  ion-item-sliding.track-jic ion-label{
    border-left: 4px solid var(--ion-color-jic);
    padding-left: 10px;
  }
  ion-item-sliding.track-posters ion-label{
    border-left: 4px solid var(--ion-color-posters);
    padding-left: 10px;
  }
  ion-item-sliding.track-otros ion-label{
    border-left: 4px solid var(--ion-color-otros);
    padding-left: 10px;
  }
  ion-item-sliding.track-patrocinadores-vip ion-label{
    border-left: 4px solid var(--ion-color-patrocinadores-vip);
    padding-left: 10px;
  }
  ion-item-sliding.track-conferencia-plenaria ion-label{
    border-left: 4px solid var(--ion-color-conferencia-plenaria);
    padding-left: 10px;
  }
  ion-item-sliding.track-simposio ion-label{
    border-left: 4px solid var(--ion-color-simposio);
    padding-left: 10px;
  }
  ion-item-sliding.track-mesa-redonda ion-label{
    border-left: 4px solid var(--ion-color-mesa-redonda);
    padding-left: 10px;
  }
  ion-item-sliding.track-conferencia-por-invitación ion-label{
    border-left: 4px solid var(--ion-color-conferencia-por-invitación);
    padding-left: 10px;
  }
  ion-item-sliding.track-comunicaciones-orales ion-label{
    border-left: 4px solid var(--ion-color-comunicaciones-orales);
    padding-left: 10px;
  }
  ion-item-sliding.track-embajadas ion-label{
    border-left: 4px solid var(--ion-color-embajadas);
    padding-left: 10px;
  }
  ion-item-sliding.track-turbo-oral ion-label{
    border-left: 4px solid var(--ion-color-turbo-oral);
    padding-left: 10px;
  }
  ion-item-sliding.track-isit ion-label{
    border-left: 4px solid var(--ion-color-isit);
    padding-left: 10px;
  }
  ion-item-sliding.track-rpai ion-label{
    border-left: 4px solid var(--ion-color-rpai);
    padding-left: 10px;
  }

  ion-item-sliding.track-navigation ion-label {
    border-left: 4px solid var(--ion-color-navigation);
    padding-left: 10px;
  }
}
