#speaker-list {
  .speaker-card {
    display: flex;
    flex-direction: column;
  }

  /* Due to the fact the cards are inside of columns the margins don't overlap
   * properly so we want to remove the extra margin between cards
   */
  ion-col:not(:last-of-type) .speaker-card {
    margin-bottom: 0;
  }

  .speaker-card .speaker-item {
    --min-height: 85px;
  }

  .speaker-card .speaker-item h2 {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.02em;
  }

  .speaker-card .speaker-item p {
    font-size: 13px;
    letter-spacing: 0.02em;
  }

  .speaker-card ion-card-header {
    padding: 0;
  }

  .speaker-card ion-card-content {
    flex: 1 1 auto;

    padding: 0;
  }

  .ios ion-list {
    margin-bottom: 10px;
  }

  .md ion-list {
    border-top: 1px solid var(--ion-color-step-150, #d7d8da);

    padding: 0;
  }
}